<script setup>
import { reactive, ref, computed, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { schemaMaker, validateForms, image } from '@valid/JoiValidation'
import { getConfirmData, sendConfirmData } from '@service/ProfileService'
import { setRegisterComplete } from '@service/TokenService'
import { setSocials } from '@service/ProfileService'
import useCropper from '@composable/imgCropper'
import DropzoneModal from '@common/DropzoneModal'
import SocialInput from '@common/SocialInput'
import ProfileRuleContent from './subComponents/ProfileRuleContent'

/*******************
 * INIT FORM ITEMS *
 *******************/
const btnLoading = ref(false)
const store = useStore()
const router = useRouter()
const formItems = reactive({
  storeNameFa: '',
  storeNameEn: '',
  storeLogo: ''
})
const getFormItems = async () => {
  try {
    const { data } = await getConfirmData()
    formItems['storeNameFa'] = data['store_name_fa'] ?? ''
    formItems['storeNameEn'] = data['store_name_en'] ?? ''
    formItems['storeLogo'] = data['image'] ?? ''
  } catch (ex) {
    console.log(ex)
  }
}
getFormItems()

/******************
 * HANDLE CROPPER *
 ******************/
const {
  cropperModal,
  resizedImg,
  imgRef,
  croppedImgBase64,
  croppedImgFile,
  resetCropper,
  handleStartCropper,
  getBothOutputs
} = useCropper()

watchEffect(() => {
  if (croppedImgBase64.value) {
    formItems['storeLogo'] = croppedImgBase64.value
  }
})
/***********************
 * SET SOCIAL NETWORKS *
 ***********************/
const socialStatus = ref('loading')
const clonedSocials = ref([])
store.dispatch('profile/fetchSocials')
const socials = computed(() => store.state.profile.socials)

// Clone socials
watch(socials, (curr) => {
  if (curr && curr.length > 0) {
    socialStatus.value = 'resolved'
    clonedSocials.value = [...curr]
  }
})
/***********************
 * HANDLE PAGE CONTENT *
 ***********************/
const pageContent = ref('form')
const handlePageContent = (type) => {
  pageContent.value = type
  window.scrollTo({ top: 0 })
}
/******************
 * FORM SUBMITION *
 ******************/
const isValid = schemaMaker({
  storeLogo: image('لوگوی فروشگاه')
})
const submitForm = async () => {
  if (btnLoading.value) return
  const { storeLogo } = formItems

  const errors = validateForms(
    {
      storeLogo
    },
    isValid
  )

  if (errors && errors.length > 0) {
    for (let error of errors) {
      const message = {
        type: 'error',
        message: error
      }
      store.dispatch('addToast', message)
    }
    return
  }

  const finalForm = {}
  finalForm['image'] = croppedImgFile.value

  btnLoading.value = true
  try {
    await sendConfirmData(finalForm)
    await setSocials(clonedSocials.value)

    store.dispatch('addToast', {
      type: 'success',
      message: 'اطلاعات شما با موفقیت تکمیل شد.'
    })
    // Make completed true in localStorage
    setRegisterComplete()
    // Redirect to home
    router.push({ name: 'Home' })
  } catch (ex) {
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت آمیز نبود.'
    })
  } finally {
    btnLoading.value = false
  }
}
</script>

<template>
  <div class="cmp-wrapper">
    <keep-alive>
      <form
        novalidate
        class="form-wrapper"
        @submit.prevent="submitForm"
        v-if="pageContent == 'form'"
      >
        <div class="form-top">
          لطفاً عضویت را تکمیل بفرمایید
        </div>
        <div class="form-info">
          <h4>{{ formItems['storeNameFa'] }}</h4>
        </div>
        <div class="form-info">
          <h4>{{ formItems['storeNameEn'] }}</h4>
        </div>
        <div
          id="img-dropzone"
          class="dropzone-container"
          @dragover.prevent
          @drop.prevent
        >
          <div class="file-wrapper">
            <div
              class="logo"
              v-if="!(croppedImgBase64 || formItems['storeLogo'])"
            >
              <span>لوگو</span>
              <span>LOGO</span>
            </div>
            <img
              v-if="croppedImgBase64 || formItems['storeLogo']"
              :src="croppedImgBase64 || formItems['storeLogo']"
            />
            <input
              type="file"
              name="file-input"
              accept="image/*"
              @change="handleStartCropper"
            />
          </div>
        </div>
        <h4 class="cmp-title">
          شبکه‌های اجتماعی
        </h4>
        <SocialInput
          v-for="item in clonedSocials"
          :key="item['id']"
          :social-icon="item['image']"
          :label="item['name']"
          v-model="item['username']"
          style="width:90%;"
          startLeft
          animateType="static"
          :charCountToRemove="0"
        />
        <a class="cmp-title" @click="handlePageContent('rules')">
          <span style="color: #c69d4e; cursor: pointer;">قوانین</span>
          را می‌پذیرم.
        </a>
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <span class="button__text">
              ادامه
            </span>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
      <div class="cmp-items-wrapper" v-else>
        <profile-rule-content />
        <div class="round-btns confirm--btns">
          <button
            type="button"
            class="round-btn"
            @click="handlePageContent('form')"
          >
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
          </button>
        </div>
      </div>
    </keep-alive>
    <dropzone-modal :is-open="cropperModal" @modalClosed="cropperModal = false">
      <div class="upload-img-wrapper">
        <div class="round-btns cropper--btns">
          <a class="round-btn" @click="getBothOutputs">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
          </a>
          <a class="round-btn" @click="resetCropper">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
              />
            </svg>
          </a>
        </div>
        <div class="img-wrapper">
          <img :src="resizedImg" ref="imgRef" />
        </div>
      </div>
    </dropzone-modal>
  </div>
</template>

<style lang="scss" scoped>
.cmp-wrapper {
  background: linear-gradient(to bottom, #fff 0, #fff 330px, #eee 460px);
  padding: 0;
}
.cmp-title {
  margin-top: 30px;
}
.form-info {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  h4 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    // margin-right: 8px;
    text-transform: uppercase;
  }
  &:first-child {
    margin: 35px 0 10px;
    h4 {
      font-size: 18px;
    }
  }
  h3 {
    font-size: 14px;
  }
}

// change in common css
.items-wrapper li a {
  font-size: 18px;
}
.confirm--btns {
  margin: 0 0 30px;
  justify-content: center;
  padding: 0 20px;
  .round-btn {
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(#000, 0.1);
  }
}
.cmp-items-wrapper {
  margin-top: 35px;
}
.dropzone-container {
  margin-bottom: 10px;
}
.form-top {
  width: 100%;
  height: 100px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
</style>

<template>
  <form novalidate @submit.prevent="submitForm" class="form-wrapper">
    <base-input
      label="نام شعبه"
      animate-type="decrease"
      v-model="formItems['branchName']"
      star-label
    />
    <base-input
      label="زمینه فعالیت"
      animate-type="decrease"
      v-model="formItems['branchActivity']"
      star-label
    />
    <div class="credit-buttons">
      <a class="button button--form" @click="openProvinceModal">
        <span class="button__text">{{
          formItems['branchCity'] && formItems['branchProvince']
            ? formItems['branchProvince']['name'] +
              ' / ' +
              formItems['branchCity']['name']
            : 'انتخاب استان / شهر'
        }}</span>
      </a>
    </div>
    <div class="bta-wrapper">
      <box-textarea
        label="آدرس "
        animateType="remove"
        v-model="formItems['branchAddress']"
      />
    </div>
    <base-input
      label="تلفن"
      input-type="number"
      style="margin:20px 0"
      v-model="formItems['branchPhone']"
      start-left
      phone-number
    />
    <a class="img">
      <img
        class="img__img"
        v-if="croppedImgBase64 || formItems['branchImg']"
        :src="croppedImgBase64 || formItems['branchImg']"
      />
      <input
        class="img__input"
        type="file"
        name="file-input"
        accept="image/*"
        @change="handleStartCropper"
      />
      <div
        class="img__outer"
        v-if="!(croppedImgBase64 || formItems['branchImg'])"
      >
        <div class="img__inner">
          <svg
            class="img__icon"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd;width: 50px;height: 50px;"
            viewBox="0 0 800 512"
          >
            <path
              fill="#fff"
              d="M652.28 209.19l0 288.63c-0.17,7.92 -6.67,14.18 -14.57,14.18l-476.07 0c-0.1,0 -0.21,0 -0.32,-0.01 -7.64,-0.19 -13.76,-6.57 -13.56,-14.21l0 -288.59 -2.02 0c-21.66,0 -39.11,-18 -39.11,-39.54l0 -52.03c-0.02,-0.12 -0.04,-0.24 -0.04,-0.37 0.01,-0.4 -0.01,-0.79 0.03,-1.19 0.01,-0.19 0.03,-0.37 0.06,-0.55 0.03,-0.23 0.06,-0.46 0.1,-0.69 0.04,-0.2 0.07,-0.4 0.12,-0.58 0.04,-0.19 0.08,-0.36 0.13,-0.54 0.14,-0.52 0.3,-1.02 0.48,-1.49 0.02,-0.05 0.03,-0.09 0.05,-0.15l0.03 -0.08c0.11,-0.25 0.22,-0.5 0.34,-0.75l0.05 -0.11c0.04,-0.06 0.06,-0.12 0.09,-0.18 0.13,-0.26 0.27,-0.51 0.43,-0.78l0.03 -0.05c0.03,-0.06 0.07,-0.11 0.1,-0.17 0.17,-0.28 0.35,-0.54 0.53,-0.81l64.49 -92.39c7.33,-10.49 19.33,-16.74 32.13,-16.74l398.33 0c13.85,0 26.69,7.32 33.73,19.24l53.66 90.83c0.03,0.05 0.04,0.08 0.06,0.11 0.6,1.04 1.06,2.14 1.39,3.3 0.02,0.09 0.04,0.19 0.06,0.26 0.12,0.49 0.22,0.99 0.28,1.49 0.02,0.1 0.02,0.2 0.03,0.28l0.01 0.11c0.06,0.56 0.09,1.13 0.07,1.68l0 52.35c0,21.54 -17.44,39.54 -39.1,39.54l-2.02 0zm-504.12 -105.76l506.09 0 -40.96 -69.51c-1.92,-3.26 -5.39,-5.35 -9.17,-5.41l-398.3 0c-3.53,0.06 -6.78,1.84 -8.79,4.73l-48.87 70.19zm307.39 380.06l0 -165.89 -111.06 0 0 165.89 111.06 0zm168.22 0l0 -274.3 -447.5 0 0 274.3 139.72 0 -0.01 -180.09c-0.08,-7.82 6.19,-14.21 14.02,-14.3l139.36 0c8,0 14.47,6.39 14.7,14.38l0 180.01 139.71 0zm-342.13 -189.41c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.39,-14.26 14.26,-14.26l78.5 0zm314.02 0c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.38,-14.26 14.26,-14.26l78.5 0zm-328.27 73.52l0 -45.01 -50 0 0 45.01 50 0zm314.02 0l0 -45.01 -50 0 0 45.01 50 0zm83.49 -235.66l-529.75 0 0 37.71c0,5.93 4.63,10.88 10.57,11.04l508.52 -0.01c5.98,-0.16 10.66,-5.07 10.66,-11.03l0 -37.71z"
            />
          </svg>
          <span class="img__text">تصویر شعبه</span>
          <span class="img__text">Branch</span>
        </div>
      </div>
    </a>
    <div class="credit-buttons">
      <a class="button button--form" @click="$emit('pick-map')">
        <svg
          class="button__icon"
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; fill: #c69d4e; width: 25px; height: 25px"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0c-99.25,0 -180,80.75 -180,180 0,33.53 9.29,66.26 26.87,94.65l142.88 230.26c2.74,4.41 7.56,7.09 12.75,7.09 0.04,0 0.08,0 0.12,0 5.23,-0.04 10.06,-2.8 12.75,-7.29l139.24 -232.49c16.61,-27.79 25.39,-59.68 25.39,-92.22 0,-99.25 -80.75,-180 -180,-180zm128.87 256.82l-126.6 211.37 -129.9 -209.34c-14.64,-23.64 -22.57,-50.9 -22.57,-78.85 0,-82.71 67.49,-150.2 150.2,-150.2 82.71,0 150.1,67.49 150.1,150.2 0,27.12 -7.41,53.69 -21.23,76.82z"
          />
          <path
            d="M256 90c-49.63,0 -90,40.37 -90,90 0,49.31 39.72,90 90,90 50.9,0 90,-41.23 90,-90 0,-49.63 -40.37,-90 -90,-90zm0 150.2c-33.26,0 -60.2,-27.03 -60.2,-60.2 0,-33.08 27.11,-60.2 60.2,-60.2 33.08,0 60.1,27.12 60.1,60.2 0,32.68 -26.32,60.2 -60.1,60.2z"
          />
        </svg>
        <span class="button__text">
          موقعیت مکانی
        </span>
      </a>
    </div>
    <div class="bta-wrapper">
      <box-textarea
        label="توضیحات"
        animateType="remove"
        v-model="formItems['branchExp']"
      />
    </div>
    <div :class="['credit-buttons', mode === 'edit' && 'buttons--edit']">
      <button
        type="button"
        class="button button--remove"
        v-if="mode === 'edit'"
        @click="handleDeleteBranch"
      >
        <span v-text="'حذف شعبه'" />
        <spinner v-if="btnLoadingDelete" />
      </button>
      <button type="submit" class="button button--black">
        <span class="button__text" v-text="confirmBtnText" />
        <spinner v-if="btnLoading" />
      </button>
    </div>
    <modal
      title="انتخاب استان"
      :is-open="modals['province']"
      @modalClosed="modals['province'] = false"
    >
      <ul class="items-wrapper">
        <li v-for="province in provinces" :key="province">
          <a @click="() => handleSelectProvince(province)">
            {{ province['name'] }}
          </a>
        </li>
      </ul>
    </modal>
    <modal
      title="انتخاب شهر"
      :is-open="modals['city']"
      @modalClosed="
        () => {
          modals['city'] = false
          formItems['branchProvince'] = ''
          formItems['branchCity'] = ''
        }
      "
    >
      <loader v-if="isLoading" />
      <ul class="items-wrapper" v-else>
        <li v-for="city in cities" :key="city">
          <a
            @click="
              () => {
                formItems['branchCity'] = city
                modals['city'] = false
              }
            "
          >
            {{ city['name'] }}
          </a>
        </li>
      </ul>
    </modal>
    <dropzone-modal :is-open="cropperModal" @modalClosed="cropperModal = false">
      <div class="upload-img-wrapper">
        <div class="round-btns cropper--btns">
          <a class="round-btn" @click="getBothOutputs">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
          </a>
          <a class="round-btn" @click="resetCropper">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
              />
            </svg>
          </a>
        </div>
        <div class="img-wrapper">
          <img :src="resizedImg" ref="imgRef" />
        </div>
      </div>
    </dropzone-modal>
  </form>
</template>

<script>
import { computed, reactive, ref, watchEffect } from 'vue'
import { mapState, mapActions, useStore } from 'vuex'
import { createBranch, editBranch } from '@service/ProfileService'
import { useRouter } from 'vue-router'
import BoxTextarea from '@common/BoxTextarea'
import DropzoneModal from '@common/DropzoneModal'
import imgCropper from '@composable/imgCropper'
import {
  validateForms,
  schemaMaker,
  reqString,
  reqObject,
  reqNumber,
  image
} from '@valid/JoiValidation'
import { getBranch, deleteBranch } from '@service/ProfileService'

export default {
  name: 'ProfileBranchInputs',
  components: {
    BoxTextarea,
    DropzoneModal
  },
  emits: ['add-main-branch'],
  props: {
    mode: String,
    mapInfo: Object,
    id: String
  },
  setup(props) {
    // Define formItems
    const modals = reactive({ province: false, city: false, cropper: false })
    const formItems = reactive({
      branchName: '',
      branchActivity: '',
      branchAddress: '',
      branchPhone: '',
      branchExp: '',
      branchCity: '',
      branchProvince: '',
      branchImg: '',
      branchLat: props.mapInfo['lat'],
      branchLng: props.mapInfo['lng']
    })
    const btnLoading = ref(false)

    // Handle img cropper
    const {
      cropperModal,
      resizedImg,
      imgRef,
      croppedImgBase64,
      croppedImgFile,
      resetCropper,
      handleStartCropper,
      getBothOutputs
    } = imgCropper()

    // Initial calls
    const store = useStore()
    const router = useRouter()
    store.dispatch('profile/fetchProvinces')
    const provinces = computed(() => store.state.profile.provinces)
    // check for provinces when the modal will open
    const openProvinceModal = () => {
      if (provinces.value.length === 0) store.dispatch('profile/fetchProvinces')
      modals['province'] = true
    }
    // If mode===edit get initial branch
    const assignBranchItems = async () => {
      try {
        const { data } = await getBranch(props.id)
        const { province_name, city_name, province, city } = data
        formItems['branchName'] = data['title']
        formItems['branchActivity'] = data['field']
        formItems['branchAddress'] = data['address']
        formItems['branchPhone'] = data['tel'] ?? ''
        formItems['branchExp'] = data['desc']
        formItems['branchCity'] = { name: city_name, id: city }
        formItems['branchProvince'] = { name: province_name, id: province }
        formItems['branchImg'] = data['image']
        croppedImgBase64.value = data['image']
        formItems['branchLat'] = data['lat']
        formItems['branchLng'] = data['lng']
      } catch (ex) {
        console.log(ex)
      }
    }
    if (props.mode === 'edit') {
      assignBranchItems()
    }
    watchEffect(() => {
      if (props.mapInfo.lat) {
        const { lat, lng } = props.mapInfo
        formItems['branchLat'] = lat
        formItems['branchLng'] = lng
      }
    })

    // Form submition
    const validSchema = schemaMaker({
      branchName: reqString('نام شعبه'),
      branchActivity: reqString('فعالیت شعبه'),
      branchAddress: reqString('آدرس شعبه'),
      // #TODO phone number only accepts number
      // branchPhone: reqString('شماره تلفن'),
      branchImg: image('تصویر شعبه'),
      // branchProvince: reqObject('استان'),
      branchCity: reqObject('استان و شهر'),
      branchLat: reqNumber('موقعیت مکانی')
    })

    const submitForm = async () => {
      const {
        branchName,
        branchActivity,
        branchAddress,
        // branchPhone,
        // branchProvince,
        branchCity,
        branchLat
      } = formItems

      if (btnLoading.value) return
      const errors = validateForms(
        {
          branchName,
          branchActivity,
          branchAddress,
          // branchPhone,
          branchImg: croppedImgBase64.value,
          // branchProvince,
          branchCity,
          branchLat
        },
        validSchema
      )
      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = {
            type: 'error',
            message: key
          }
          store.dispatch('addToast', message)
        }
        return
      }

      btnLoading.value = true
      if (props.mode === 'new') {
        formItems['branchImg'] = croppedImgFile.value
        try {
          await createBranch(formItems)
          router.push({ name: 'ProfileBranches' })
          store.dispatch('addToast', {
            type: 'success',
            message: 'شعبه جدید با موفقیت ایجاد شد.'
          })
        } catch (ex) {
          if (ex.response) {
            store.dispatch('addToast', {
              type: 'error',
              message: 'درخواست شما موفقیت‌ آمیز نبود.'
            })
          }
        } finally {
          btnLoading.value = false
        }
      } else if (props.mode === 'edit') {
        // Img is not required for backend just ignore it
        if (croppedImgFile.value) {
          formItems['branchImg'] = croppedImgFile.value
        } else {
          formItems['branchImg'] = null
        }
        try {
          await editBranch(props.id, formItems)
          store.dispatch('addToast', {
            type: 'success',
            message: 'تغییرات مورد نظر با موفقیت اعمال شد.'
          })
          router.push({ name: 'ProfileBranches' })
        } catch (ex) {
          if (ex.response) {
            store.dispatch('addToast', {
              type: 'error',
              message: 'درخواست شما موفقیت‌ آمیز نبود.'
            })
          }
        } finally {
          btnLoading.value = false
        }
      }
    }

    // Delete branch
    const btnLoadingDelete = ref(false)

    const handleDeleteBranch = async () => {
      btnLoadingDelete.value = true
      try {
        await deleteBranch(props.id)
        router.push({ name: 'ProfileBranches' })
        store.dispatch('addToast', {
          type: 'success',
          message: 'شعبه با موفقیت حذف گردید.'
        })
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌ آمیز نبود.'
          })
        }
      } finally {
        btnLoadingDelete.value = false
      }
    }

    return {
      provinces,
      openProvinceModal,
      formItems,
      modals,
      // img cropper
      cropperModal,
      resizedImg,
      imgRef,
      croppedImgBase64,
      croppedImgFile,
      resetCropper,
      handleStartCropper,
      getBothOutputs,
      // submit form
      submitForm,
      btnLoading,
      // Delete branch
      handleDeleteBranch,
      btnLoadingDelete
    }
  },
  methods: {
    ...mapActions('profile', ['fetchCities']),
    handleSelectProvince(province) {
      this.formItems['branchProvince'] = province
      // Calling action from vuex
      this.fetchCities(province.id)
      this.modals['province'] = false
      this.modals['city'] = true
    }
  },
  computed: {
    ...mapState({
      cities: (state) => state.profile.cities,
      isLoading: (state) => state.profile.isLoading
    }),
    userActiveText() {
      return this.userActive ? 'فعال' : 'غیرفعال'
    },
    confirmBtnText() {
      if (this.mode === 'create') {
        return 'ایجاد شعبه'
      } else if (this.mode === 'edit') {
        return 'ثبت'
      } else {
        return 'ثبت'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bta-wrapper {
  width: 90%;
  padding: 0;
  // margin: 12px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-file-input {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.img {
  margin: 15px 0 0;
  overflow: hidden;
  position: relative;
  width: 90%;
  max-width: 800px;
  color: white;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img__img {
  width: 100%;
}
.img__outer {
  // use to set height
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  padding: 12px;
  border-radius: 15px;
  background-color: #d2d2d2;
  width: 110px;
}
.img__icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  pointer-events: none;
}
.img__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}
.img__input {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

// change in common css
.credit-buttons {
  margin: 30px 0;
}
.buttons--edit {
  width: 90%;
  // margin: 20px 0;
  justify-content: space-between;
}
.buttons--edit .button--black {
  width: calc(50% - 5px);
}
.button--modal {
  margin: 0;
}
.items-wrapper li a {
  font-size: 18px;
}

.confirm--btns {
  margin: 30px 0 30px;
  justify-content: center;
  padding: 0 20px;
  .round-btn {
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(#000, 0.1);
  }
}
</style>

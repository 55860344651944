<template>
  <div class="profile-component-wrapper">
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="credit-buttons">
        <router-link
          :to="{ name: 'ProfileBranchNew', params: { mode: 'new' } }"
          class="button button--form"
        >
          <svg viewBox="0 0 512 512" class="button__icon">
            <path
              d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            />
            <path
              d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
            />
          </svg>
          <span class="button__text">شعبه جدید</span>
        </router-link>
      </div>
      <div class="cmp-items-wrapper">
        <profile-branch-single
          v-for="branch in branches"
          :key="branch['id']"
          :title="branch['title']"
          :image="branch['image']"
          @click="editBranch(branch['id'])"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ProfileBranchSingle from './subComponents/ProfileBranchSingle'
import { useRouter } from 'vue-router'

export default {
  name: 'ProfileBranches',
  components: {
    ProfileBranchSingle
  },
  setup() {
    // Fetch Branches
    const store = useStore()
    store.dispatch('profile/fetchItems', { part: 'branch' })
    const branches = computed(() => store.state.profile.items)
    const pageStatus = computed(() => store.state.profile.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('profile/fetchItems', { part: 'branch' })
    }

    // Routing for edit branches
    const router = useRouter()
    const editBranch = (id) => {
      router.push({ name: 'ProfileBranchNew', params: { mode: 'edit', id } })
    }

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Clear branches
    onBeforeUnmount(() => store.dispatch('profile/clearItems'))

    return { branches, pageStatus, intersected, editBranch, firstLoad }
  }
}
</script>

<style lang="scss" scoped>
.profile-component-wrapper {
  min-height: calc(100vh - 278px);
}
.load-pending--show {
  height: calc(100vh - 278px);
  img {
    position: relative;
    bottom: 30px;
  }
}
.credit-buttons {
  margin-top: 0px;
}
.button--form {
  margin-top: 0;
  position: relative;
  .button__icon {
    position: absolute;
    right: 10px;
    fill: #777;
  }
}
</style>

<template>
  <div :class="{ centerTitle: centerTitle }">
    <h1>{{ faTitle }}</h1>
    <h2 v-if="enTitle">{{ enTitle }}</h2>
    <span :style="{ backgroundColor: rightWallColor }"></span>
  </div>
</template>

<script>
export default {
  name: 'InputHeader',
  props: {
    faTitle: {
      type: String,
      default: 'بدون عنوان'
    },
    enTitle: {
      type: String
    },
    rightWallColor: {
      type: String,
      default: '#000'
    },
    centerTitle: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
div {
  margin: 22px 0 15px;
  overflow: hidden;
  position: relative;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 20px;
  background: linear-gradient(to left, #333 0, #131313 100%);
  border-radius: 15px 15px 0 0;
  h1,
  h2 {
    margin: 0;
  }

  h1 {
    color: #fff;
    font-size: 16px;
  }

  h2 {
    color: #fff;
    font-size: 18px;
    margin: 0;
    margin-top: 5px;
  }

  span {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
  }
  &.centerTitle {
    justify-content: center;
    background: linear-gradient(to right, #131313 0, #333 100%);
  }
}
</style>

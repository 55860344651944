<template>
  <div class="cmp-items-wrapper">
    <navbar :msg="navTitle" />
    <keep-alive>
      <profile-branch-inputs
        :mode="mode"
        :id="id"
        :mapInfo="mapInfo"
        v-if="!showMap"
        @pickMap="showMap = true"
      />
      <profile-branch-map
        v-else-if="showMap"
        @sendMap="setMap"
        @cancelMap="showMap = false"
      />
    </keep-alive>
    <fixed-footer />
  </div>
</template>

<script>
import ProfileBranchInputs from './subComponents/ProfileBranchInputs'
import ProfileBranchMap from './subComponents/ProfileBranchMap'

export default {
  components: {
    ProfileBranchInputs,
    ProfileBranchMap
  },
  data() {
    return {
      showMap: false,
      current: 'profile-branch-inputs',
      bgMap: null,
      mapInfo: { lat: '', lng: '' }
    }
  },
  props: {
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  methods: {
    selectMap() {
      this.current = 'profile-branch-map'
    },
    addMapCdns() {
      let scripts = ['https://static.neshan.org/sdk/leaflet/1.4.0/leaflet.js']
      let styles = ['https://static.neshan.org/sdk/leaflet/1.4.0/leaflet.css']
      scripts.forEach((script) => {
        let tag = document.createElement('script')
        tag.setAttribute('src', script)
        tag.setAttribute('defer', '')
        tag.setAttribute('async', '')
        document.head.appendChild(tag)
      })
      styles.forEach((style) => {
        let link = document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('href', style)
        document.head.appendChild(link)
      })
    },
    // #TODO remove after leaving
    removeMapCdns() {},
    setMap(payload) {
      const { lat, lng } = payload
      this.mapInfo = { lat, lng }
      this.showMap = false
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    }
  },
  computed: {
    navTitle() {
      if (this.mode === 'new') {
        return 'ایجاد شعبه جدید'
      } else if (this.mode === 'edit') {
        return 'ویرایش شعبه'
      } else {
        return 'ویرایش شعبه'
      }
    }
  },
  mounted() {
    this.addMapCdns()
  },
  beforeUnmount() {
    this.removeMapCdns()
  }
}
</script>

<style lang="scss" scoped>
.cmp-items-wrapper {
  padding: 80px 0 60px;
}
</style>

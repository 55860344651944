<template>
  <div class="inputs-wrapper">
    <div class="map-wrapper" id="map">
      <svg viewBox="0 0 512 512">
        <path
          class="fil0"
          d="M256 0c-99.25,0 -180,80.75 -180,180 0,33.53 9.29,66.26 26.87,94.65l142.88 230.26c2.74,4.41 7.56,7.09 12.75,7.09 0.04,0 0.08,0 0.12,0 5.23,-0.04 10.06,-2.8 12.75,-7.29l139.24 -232.49c16.61,-27.79 25.39,-59.68 25.39,-92.22 0,-99.25 -80.75,-180 -180,-180zm128.87 256.82l-126.6 211.37 -129.9 -209.34c-14.64,-23.64 -22.57,-50.9 -22.57,-78.85 0,-82.71 67.49,-150.2 150.2,-150.2 82.71,0 150.1,67.49 150.1,150.2 0,27.12 -7.41,53.69 -21.23,76.82z"
        />
        <path
          class="fil0"
          d="M256 90c-49.63,0 -90,40.37 -90,90 0,49.31 39.72,90 90,90 50.9,0 90,-41.23 90,-90 0,-49.63 -40.37,-90 -90,-90zm0 150.2c-33.26,0 -60.2,-27.03 -60.2,-60.2 0,-33.08 27.11,-60.2 60.2,-60.2 33.08,0 60.1,27.12 60.1,60.2 0,32.68 -26.32,60.2 -60.1,60.2z"
        />
      </svg>
    </div>
    <div class="btns">
      <a class="add-btn" @click="sendBackMap">
        <svg viewBox="0 0 512 512">
          <path
            fill="#c69d4e"
            d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
          />
        </svg>
      </a>
      <a class="add-btn" @click="cancelMap">
        <svg viewBox="0 0 512 512">
          <path
            fill="#777"
            d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
// #TODO add a new simple navbar here
export default {
  name: 'ProfileBranchMap',
  data() {
    return {
      map: null,
      img: null,
      apiKey: 'web.mykwHWNiauJidUjjfbaGd4iU9cNVZnVGtrb23IzT',
      userCoords: {}
    }
  },
  methods: {
    createMap() {
      this.map = new window.L.Map('map', {
        renderer: window.L.canvas(),
        key: this.apiKey,
        maptype: 'dreamy',
        poi: true,
        traffic: false,
        center: [35.699739, 51.338097],
        zoom: 15,
        preferCanvas: true
      })
    },
    sendBackMap() {
      //#TODO 1) api call 2) send back {lat, lon}, imgurl
      this.$emit('send-map', this.map.getCenter())
    },
    cancelMap() {
      this.$emit('cancel-map')
    },
    getUserLocation() {
      const allowAccess = (position) => {
        const { coords } = position
        this.userCoords['lat'] = coords.latitude
        this.userCoords['lon'] = coords.longitude
        this.map.panTo(this.userCoords)
      }
      const rejectAccess = (error) => {
        console.log(error)
      }

      navigator.geolocation.getCurrentPosition(allowAccess, rejectAccess)
      // this.map.locate({ setView: true })
    }
  },
  mounted() {
    this.createMap()
    setTimeout(this.getUserLocation, 1000)
  }
}
</script>

<style lang="scss" scoped>
.inputs-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  .map-wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    svg {
      z-index: 500;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      fill: #c69d4e;
      position: absolute;
      width: 40px;
      height: 40px;
    }
  }
  .btns {
    margin-top: 20px;
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-btn {
      height: 70px;
      padding-bottom: 5px;
      width: 49%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background-color: #111;
      cursor: pointer;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>

import { ref, nextTick } from 'vue'
import Cropper from 'cropperjs'
import { canvasToBlob } from 'blob-util'
import imgConvertor from './imgConvertor'

const imgCropper = () => {
  // Adding necessary functions
  const { resizeImg } = imgConvertor()

  // Cropper holder
  const cropper = ref(null)
  // Image tag holder
  const imgRef = ref(null)
  // Cropper Modal
  const cropperModal = ref(false)
  // Resized Image
  const resizedImg = ref(null)
  // Cropped Image
  const croppedImgBase64 = ref('')
  const croppedImgFile = ref(null)
  // Cropper canvas
  const cropperCanvas = ref(null)

  // Initialize cropper
  const initCropper = () => {
    cropper.value = new Cropper(imgRef.value, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      viewMode: 1
    })
  }

  // Reading file and start cropper
  const handleStartCropper = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      cropperModal.value = true
      resizedImg.value = await resizeImg(e.target.files[0], 500)
      nextTick(() => {
        initCropper()
      })
    }
  }

  // Reset cropper
  const resetCropper = () => {
    cropperModal.value = false
    resizedImg.value = null
    cropper.value.destroy()
  }

  // Get copped canvas
  const getCropperCanvas = () => {
    cropperCanvas.value = cropper.value.getCroppedCanvas()
  }

  // Generate output
  const generateBase64 = () => {
    croppedImgBase64.value = cropperCanvas.value.toDataURL('image/jpg')
  }

  const generateFile = async () => {
    const blob = await canvasToBlob(cropperCanvas.value, 'image/jpeg')
    blob.lastModifiedDate = new Date()
    blob.name = 'viponFile.jpg'
    croppedImgFile.value = new File([blob], 'viponFile.jpg')
  }

  const getBothOutputs = () => {
    getCropperCanvas()
    generateBase64()
    generateFile()
    resetCropper()
  }

  return {
    imgRef,
    resizedImg,
    cropperModal,
    croppedImgBase64,
    resetCropper,
    handleStartCropper,
    croppedImgFile,
    getBothOutputs
  }
}

export default imgCropper

<template>
  <div class="branch">
    <img class="branch__img" :src="image" />
    <div class="branch__filter" />
    <a class="branch__inner">
      <span class="branch__text">{{ title }}</span>
      <svg class="branch__icon" viewBox="0 0 492 492">
        <g>
          <path
            d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
			C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
			c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
			l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
          />
        </g>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ProfileBranchSingle',
  props: {
    title: {
      type: String,
      default: 'نام شعبه'
    },
    image: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.branch {
  width: 92%;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  z-index: 4;
  margin-bottom: 15px;
}
.branch__inner {
  z-index: 3;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
.branch__img {
  z-index: 1;
  width: 100%;
  min-height: 300px;
  filter: saturate(0.15);
}
.branch__text {
  display: inline-block;
  position: relative;
  bottom: 5px;
  margin-left: 5px;
}
.branch__filter {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(#000, 0.3) 40%,
    rgba(#000, 0.8) 100%
  );
}
.branch__icon {
  position: relative;
  fill: #fff;
  top: 2px;
  width: 20px;
  height: 20px;
}
</style>

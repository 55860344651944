<template>
  <div class="profile-component-wrapper">
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="fetchProfile" />
    <section class="load-resolved" v-else>
      <form novalidate class="form-wrapper" @submit.prevent="submitForm">
        <div class="form-info">
          <h4>{{ formItems['profileNameFa'] }}</h4>
        </div>
        <div class="form-info">
          <h4>{{ formItems['profileNameEn'] }}</h4>
        </div>
        <div class="dropzone-container" @dragover.prevent @drop.prevent>
          <div class="file-wrapper">
            <div
              class="logo"
              v-if="!(croppedImgBase64 || formItems['profileLogo'])"
            >
              <span>لوگو</span>
              <span>LOGO</span>
            </div>
            <img
              v-if="croppedImgBase64 || formItems['profileLogo']"
              :src="croppedImgBase64 || formItems['profileLogo']"
            />
            <input
              type="file"
              name="file-input"
              accept="image/*"
              @change="handleStartCropper"
            />
          </div>
        </div>
        <div class="bta-wrapper">
          <h5 class="bta-text">پیام عضویت</h5>
          <box-textarea
            label="پیام عضویت"
            animateType="remove"
            width="90%"
            height="140px"
            starLabel
            v-model="formItems['profileMsgReg']"
          />
        </div>
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <span class="button__text">ثبت</span>
            <spinner v-show="btnLoading" />
          </button>
        </div>
      </form>
    </section>
    <dropzone-modal :is-open="cropperModal" @modalClosed="cropperModal = false">
      <div class="upload-img-wrapper">
        <div class="round-btns cropper--btns">
          <a class="round-btn" @click="getBothOutputs">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
          </a>
          <a class="round-btn" @click="resetCropper">
            <svg class="round-btn__icon" viewBox="0 0 512 512">
              <path
                d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
              />
            </svg>
          </a>
        </div>
        <div class="img-wrapper">
          <img :src="resizedImg" ref="imgRef" />
        </div>
      </div>
    </dropzone-modal>
  </div>
</template>

<script>
import { reqString, validateForms, schemaMaker } from '@valid/JoiValidation'
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { dateMixin } from '@mixin/dateMixin'
import { editProfile } from '@service/ProfileService'
import { getProfile } from '@service/ProfileService'
import imgCropper from '@composable/imgCropper'
import DropzoneModal from '@common/DropzoneModal'
import BoxTextarea from '@common/BoxTextarea'

export default {
  name: 'ProfileEdit',
  mixins: [dateMixin],
  components: {
    DropzoneModal,
    BoxTextarea
  },
  setup() {
    // Define main parameters
    const btnLoading = ref(false)
    const formItems = reactive({
      profileNameFa: '',
      profileNameEn: '',
      profileLogo: '',
      // profileMsgSell: '',
      profileMsgReg: ''
    })
    const store = useStore()
    const router = useRouter()

    // Initialize formItems
    const pageStatus = ref('loading')
    const fetchProfile = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getProfile()
        formItems['profileNameFa'] = data['name']
        formItems['profileNameEn'] = data['name_en']
        formItems['profileLogo'] = data['image']
        // formItems['profileMsgSell'] = data['msg_sell']
        formItems['profileMsgReg'] = data['msg_register'] ?? ''
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    fetchProfile()

    // Initail cropper
    const {
      cropperModal,
      resizedImg,
      imgRef,
      croppedImgBase64,
      croppedImgFile,
      resetCropper,
      handleStartCropper,
      getBothOutputs
    } = imgCropper()

    // Submit form handling
    const schema = schemaMaker({
      // nameFa: reqString('نام فارسی'),
      // nameEn: reqString('نام انگلیسی'),
      // msgSell: reqString('پیام فروش'),
      msgReg: reqString('پیام عضویت')
    })
    const submitForm = async () => {
      if (btnLoading.value) return
      const {
        // profileNameFa: nameFa,
        // profileNameEn: nameEn,
        // profileMsgSell: msgSell,
        profileMsgReg: msgReg
      } = formItems
      console.log(msgReg)
      const errors = validateForms({ msgReg }, schema)
      if (errors && errors.length > 0) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }

      const finalForm = {
        // first_name: nameFa,
        // last_name: nameEn,
        msg_register: msgReg
        // msg_sell: msgSell
      }

      if (croppedImgBase64.value) {
        finalForm['image'] = croppedImgFile.value
      }

      btnLoading.value = true
      try {
        await editProfile(finalForm)
        store.dispatch('addToast', {
          type: 'success',
          message: 'اطلاعات شما با موفقیت تغییر یافت.'
        })

        store.dispatch('fetchHomeData')
        setTimeout(() => router.push({ name: 'Home' }), 1500)
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت آمیز نبود.'
        })
      } finally {
        btnLoading.value = false
      }
    }

    return {
      formItems,
      fetchProfile,
      btnLoading,
      pageStatus,
      // cropper things
      cropperModal,
      handleStartCropper,
      resizedImg,
      imgRef,
      resetCropper,
      croppedImgBase64,
      croppedImgFile,
      getBothOutputs,
      // form submition
      submitForm
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-component-wrapper {
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
  min-height: calc(100vh - 263px);
  margin-top: 5px;
}
.load-pending--show {
  height: calc(100vh - 263px);
  img {
    position: relative;
    bottom: 30px;
  }
}

.form-info {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  h4 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
  }
  &:first-child {
    margin: 0 0 10px;
    h4 {
      font-size: 18px;
    }
  }
  h3 {
    font-size: 14px;
  }
}
.bta-wrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0;
}
.bta-text {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  font-size: 14px;
  color: #777;
  padding-right: 2px;
  &::before {
    position: relative;
    content: '*';
    color: #c69d4e;
    font-size: 20px;
    top: 6px;
    margin-left: 3px;
  }
}

.credit-buttons {
  margin-top: 20px;
}

.file-wrapper {
  box-shadow: 0 0 5px 0px rgba(#000, 0.12);
}
</style>

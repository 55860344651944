<script setup>
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTokens } from '@service/AuthService'
import { getTempData, clearTempData } from '@service/TokenService'
import StoreSingle from './subComponents/StoreSingle'

// Define necessary items
const router = useRouter()
const store = useStore()
const tempData = getTempData()
if (!tempData) router.push({ name: 'Login' })

const selectedStore = ref(null)
const stores = computed(() => tempData?.stores)

const loading = ref(false)
const selectStore = async () => {
  if (loading.value) return
  if (!selectedStore.value)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'فروشگاهی انتخاب نشده است.'
    })
  const tokenForm = {
    token: tempData.token,
    store: selectedStore.value.id,
    mobile: tempData.mobile
  }

  loading.value = true
  try {
    const { data: tokenData } = await getTokens(tokenForm)
    store.dispatch('loginUser', tokenData)

    if (tokenData.completed) router.push({ name: 'Home' })
    else router.push({ name: 'Signup' })
  } catch (ex) {
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت‌آمیز نبود.'
    })
  } finally {
    loading.value = false
  }
}

// Cleaning the room
onBeforeUnmount(clearTempData)
</script>

<template>
  <div class="cmp-wrapper">
    <section class="load-pending load-pending--show" v-if="!stores">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <h2 class="cmp-title">فروشگاه مورد نظر خود را انتخاب کنید:</h2>
      <div class="cmp-items-wrapper">
        <StoreSingle
          v-for="store in stores"
          :key="store['id']"
          :title="store['first_name']"
          :title-en="store['last_name']"
          :image="store['image']"
          :is-selected="store['id'] === selectedStore?.id"
          @click="selectedStore = store"
        />
        <div class="credit-buttons">
          <button @click="selectStore" class="button button--black">
            <span class="button__text">انتخاب</span>
            <Spinner v-if="loading" />
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.cmp-wrapper {
  padding: 10px 0 25px;
}
// change in common css
.cmp-items-wrapper {
  padding: 0 5px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.credit-buttons {
  width: 100%;
  margin-bottom: 0;
  justify-content: center;
  .button--black {
    width: calc(100% - 20px);
  }
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>

<template>
  <div class="profile-component-wrapper">
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <form novalidate class="form-wrapper" @submit.prevent="submitForm">
        <social-input
          v-for="item in clonedSocials"
          :key="item['id']"
          :social-icon="item['image']"
          :label="item['name']"
          v-model="item['username']"
          style="width:90%;"
          startLeft
          animateType="static"
          :charCountToRemove="0"
        />
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <span class="button__text">ثبت</span>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { setSocials } from '@service/ProfileService'
import SocialInput from '@common/SocialInput'

export default {
  components: {
    SocialInput
  },
  setup() {
    // Set main tools
    const store = useStore()
    const pageStatus = ref('loading')
    // Init api calls
    store.dispatch('profile/fetchSocials')

    // Clone social items
    const clonedSocials = ref([])
    const socials = computed(() => store.state.profile.socials)
    watch(socials, (curr) => {
      if (curr && curr.length > 0) {
        pageStatus.value = 'resolved'
        clonedSocials.value = [...curr]
      }
    })

    // Submit form
    const btnLoading = ref(false)
    const submitForm = async () => {
      btnLoading.value = true

      try {
        await setSocials(clonedSocials.value)
        store.dispatch('addToast', {
          type: 'success',
          message: 'تغییرات مورد نظر با موفقیت اعمال شد.'
        })
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
        }
      } finally {
        btnLoading.value = false
      }
    }

    return { socials, submitForm, btnLoading, clonedSocials, pageStatus }
  }
}
</script>

<style lang="scss" scoped>
.profile-component-wrapper {
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
  min-height: calc(100vh - 278px);
}
.load-pending--show {
  height: calc(100vh - 278px);
  img {
    position: relative;
    bottom: 30px;
  }
}
</style>

<template>
  <div class="modal-down-container" :class="modalPriority">
    <div
      class="modal-down-mask"
      v-if="isOpen"
      @click.self="$emit('modal-closed')"
    />
    <div
      class="modal-down-panel"
      :class="isOpen ? 'fadeIn' : 'fadeOut'"
      :style="{ transitionDuration: animationTime + 's' }"
    >
      <section class="modal-down-content">
        <slot />
      </section>
      <span
        v-if="upColor"
        :style="{ backgroundColor: upColor }"
        class="up-color"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropzoneModal',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      default: 'pop-up'
    },
    priority: {
      type: String,
      default: 'first'
    },
    height: {
      type: String,
      default: '50%'
    },
    upColor: {
      type: String
    },
    animationTime: {
      type: Number,
      default: 0.3
    }
  },
  computed: {
    modalPriority() {
      return this.priority === 'second' ? 'second' : 'first'
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-down-container {
  font-family: IranSans;
  font-size: 20px;
  text-align: center;

  .modal-down-mask {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-down-panel {
    z-index: 1000;
    border-radius: 0;
    position: fixed;
    overflow: hidden;
    display: table-cell;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translate3d(0, 0, 0);

    .up-color {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      top: -6px;
      height: 10px;
      width: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  .modal-down-content {
    max-height: 100%;
    margin-top: 0px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .modal-down-wrapper {
    display: flex;
  }

  .fadeIn {
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease-in-out;
  }

  .fadeOut {
    transform: translate3d(0, 100%, 0);
  }
}
</style>

<template>
  <div :class="['store', isSelected && 'store--selected']">
    <img class="store__img" :src="image" v-show="flip" @load="flip = !filp" />
    <img class="store__img" src="@img/preload-logo.svg" v-if="!flip" />
    <h4 class="store__name">{{ title }}</h4>
    <h5 class="store__name-en">{{ titleEn }}</h5>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

defineProps({
  title: String,
  titleEn: String,
  image: String,
  isSelected: {
    type: Boolean,
    default: false
  }
})

const flip = ref(false)
</script>

<style lang="scss" scoped>
.store {
  // flex: 100% 1 0;
  width: 100%;
  min-height: 140px;
  border-radius: 15px;
  cursor: pointer;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 0 5px 10px;
  padding: 10px 0;
}
.store__name {
  font-size: 1rem;
  margin-bottom: 8px;
}
.store__name-en {
  text-transform: uppercase;
  font-size: 0.875rem;
  color: var(--color-text-less);
  margin-bottom: 0;
}
.store__img {
  border-radius: 100%;
  height: 65px;
  width: 65px;
  box-shadow: 0 0 3px 0px rgba(#000, 0.2);
  margin-bottom: 15px;
}

.store--selected {
  min-height: 144px;
  border: 2px solid rgba(#c69d4e, 0.75);
}
</style>

<template>
  <div class="profile-component-wrapper">
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="assignOwner" />
    <section class="load-resolved" v-else>
      <input-header
        fa-title="مشخصات مالک"
        right-wall-color="transparent"
        center-title
        style="margin: 0px;"
      />
      <!-- OWNER -->
      <section class="owner">
        <div class="table">
          <div class="table__row">
            <h6 class="table__cell">
              نام
            </h6>
            <i class="table__vr" />
            <h6 class="table__cell" v-text="owner['name']" />
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell">
              نام خانوادگی
            </h6>
            <i class="table__vr" />
            <h6 class="table__cell" v-text="owner['family']" />
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell">
              جنسیت
            </h6>
            <i class="table__vr" />
            <h6
              class="table__cell"
              v-text="owner['gender'] == '1' ? 'مرد' : 'زن'"
            />
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell">
              تاریخ تولد
            </h6>
            <i class="table__vr" />
            <h6 class="table__cell" v-text="localizeDate(owner['bd'])" />
          </div>
        </div>
        <!-- <a class="profile-btn phone">
          تغییر مالک
        </a> -->
        <a class="profile-btn phone" @click="changePhone">
          تغییر شماره:
          {{ owner && owner['mobile'] }}
        </a>
        <a class="profile-btn phone" @click="handleChangePassword">
          تغییر رمز عبور
        </a>
      </section>
    </section>
    <modal
      @modalClosed="resetMobileItems"
      :is-open="modals['phone']"
      height="254px"
      type="bottom"
    >
      <form
        novalidate
        class="modal-form-wrapper"
        @submit.prevent="checkNewPhone"
      >
        <div class="footer-modal">
          <h4 class="footer-modal__text">شماره جدید</h4>
        </div>
        <base-input
          input-type="number"
          label="شماره همراه"
          start-left
          phone-number
          ref="newPhoneInput"
          v-model="newPhoneNumber"
        />
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <svg
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; width:40px;height: 40px;"
              viewBox="0 0 512 512"
              v-show="!btnLoading"
              class="button__icon"
            >
              <path
                fill="#c69d4e"
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </modal>
    <modal
      @modalClosed="resetMobileItems"
      :is-open="modals['confirmPhone']"
      height="226px"
      type="bottom"
    >
      <form
        novalidate
        class="modal-form-wrapper"
        @submit.prevent="handlePhoneCode"
      >
        <div class="footer-modal">
          <h4 class="footer-modal__text">تأیید شماره جدید</h4>
        </div>
        <base-input-little
          ref="phoneCode"
          @finishType="
            (code) => {
              $refs['newPhoneCodeBtn'].focus()
              tempCode = code
            }
          "
        />
        <div class="credit-buttons">
          <button
            type="submit"
            class="button button--black"
            ref="newPhoneCodeBtn"
          >
            <svg
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; width:40px;height: 40px;"
              viewBox="0 0 512 512"
              v-show="!btnLoading"
              class="button__icon"
            >
              <path
                fill="#c69d4e"
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </modal>
    <modal
      @modalClosed="resetPassItems"
      :is-open="modals['password']"
      height="390px"
      type="bottom"
    >
      <form
        novalidate
        class="modal-form-wrapper"
        @submit.prevent="submitChangePassword"
      >
        <div class="footer-modal">
          <h4 class="footer-modal__text">عوض کردن رمز عبور</h4>
        </div>
        <base-input
          width="90%"
          input-type="password"
          label="رمز عبور فعلی"
          start-left
          star-label
          ref="newPassInput"
          v-model="passForm['currentPass']"
          phone-number
        />
        <base-input
          width="90%"
          input-type="password"
          label="رمز عبور جدید"
          start-left
          star-label
          v-model="passForm['newPass1']"
          phone-number
        />
        <base-input
          width="90%"
          input-type="password"
          label="تکرار رمز عبور"
          start-left
          star-label
          v-model="passForm['newPass2']"
          phone-number
        />
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <svg
              width="512px"
              height="512px"
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; width:40px;height: 40px;"
              viewBox="0 0 512 512"
              v-show="!btnLoading"
            >
              <path
                fill="#c69d4e"
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </modal>
    <fixed-footer />
  </div>
</template>

<script>
import { nextTick, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import {
  schemaMaker,
  mobile,
  validateForms,
  password,
  passwordRepeat
} from '@valid/JoiValidation'
import {
  getOwner,
  checkNewPhoneCode,
  checkPhone,
  changePassword
} from '@service/ProfileService'
import InputHeader from '@common/InputHeader'
import dateConvertor from '@composable/dateConvertor'
import BaseInputLittle from '@common/BaseInputLittle'

export default {
  name: 'ProfileSetting',
  components: {
    InputHeader,
    BaseInputLittle
  },
  setup() {
    const store = useStore()
    const { localizeDate } = dateConvertor()
    const modals = reactive({
      password: false,
      confirmPassword: false,
      phone: false,
      confirmPhone: false
    })

    // Get owner info
    const pageStatus = ref('loading')
    const owner = ref(null)

    const assignOwner = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getOwner()
        owner.value = data
        pageStatus.value = 'resolved'
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }
    assignOwner()

    /***********************
     * Change phone number *
     ***********************/
    // Main formitems
    const btnLoading = ref(false)
    const newPhoneNumber = ref('')
    const tempCode = ref('')

    const resetMobileItems = () => {
      btnLoading.value = false
      newPhoneNumber.value = ''
      tempCode.value = ''
      modals['phone'] = false
      modals['confirmPhone'] = false
    }

    // Define refs for autofocusing
    const newPhoneInput = ref(null)
    const phoneCode = ref(null)

    const isMobileValid = schemaMaker({
      mobile: mobile
    })

    // Handle Click on change phone button
    const changePhone = () => {
      modals['phone'] = true
      nextTick(() => newPhoneInput.value.$el.querySelector('input').focus())
    }
    // Handle Click on send phone number
    const checkNewPhone = async () => {
      if (btnLoading.value) return
      const errors = validateForms(
        { mobile: newPhoneNumber.value },
        isMobileValid
      )

      if (errors && errors.length > 0) {
        for (let key of errors) {
          store.dispatch('addToast', {
            type: 'error',
            message: key
          })
        }
        return
      }

      btnLoading.value = true
      try {
        await checkPhone(newPhoneNumber.value)
        const n = newPhoneNumber.value
        const hiddenPhone = `${n.substring(7)}***${n.substring(0, 4)}`
        store.dispatch('addToast', {
          type: 'success',
          message: `کد تأیید به شماره ${hiddenPhone} ارسال گردید.`
        })
        // changing modals
        modals['phone'] = false
        modals['confirmPhone'] = true
        nextTick(() => {
          phoneCode.value.$el.querySelector('input').focus()
        })
      } catch (ex) {
        if (ex.response) {
          switch (ex.response.status) {
            case 409:
              store.dispatch('addToast', {
                type: 'error',
                message: '"شماره موبایل" وارد شده شماره کنونی می‌باشد.'
              })
              break

            default:
              store.dispatch('addToast', {
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
              break
          }
        }
      } finally {
        btnLoading.value = false
      }
    }
    // Handle validating code
    const handlePhoneCode = async () => {
      btnLoading.value = true

      try {
        await checkNewPhoneCode(tempCode.value)
        store.dispatch('addToast', {
          type: 'success',
          message: 'شماره شما با موفقیت تغییر یافت.'
        })
        resetMobileItems()
        assignOwner()
      } catch (ex) {
        switch (ex.response.status) {
          case 403:
            store.dispatch('addToast', {
              type: 'error',
              message: 'کد وارد شده صحیح نمی‌باشد.'
            })
            break
          case 400:
            store.dispatch('addToast', {
              type: 'error',
              message: 'زمان اعتبار کد ارسال شده پایان یافته است.'
            })
            break

          default:
            break
        }
      } finally {
        btnLoading.value = false
      }
    }

    /***********************
     *  Change   Password  *
     ***********************/
    // Define formItems
    const newPassInput = ref(null)
    const passForm = reactive({
      currentPass: '',
      newPass1: '',
      newPass2: ''
    })
    const resetPassItems = () => {
      passForm['currentPass'] = ''
      passForm['newPass1'] = ''
      passForm['newPass2'] = ''
      modals['password'] = false
      btnLoading.value = false
    }

    // Handle click on change password button
    const handleChangePassword = () => {
      modals['password'] = true
      nextTick(() => newPassInput.value.$el.querySelector('input').focus())
    }

    // Handle submit password
    const isPassValid = schemaMaker({
      currentPass: password('رمز عبور فعلی'),
      newPass1: password('رمز عبور جدید'),
      newPass2: passwordRepeat('newPass1')
    })

    const submitChangePassword = async () => {
      if (btnLoading.value) return
      const { currentPass, newPass1, newPass2 } = passForm
      const errors = validateForms(
        { currentPass, newPass1, newPass2 },
        isPassValid
      )

      if (errors && errors.length > 0) {
        for (let key of errors) {
          store.dispatch('addToast', {
            type: 'error',
            message: key
          })
        }
        return
      }

      const finalForm = {
        password_old: currentPass,
        password_new: newPass1,
        password_confirm: newPass2
      }

      btnLoading.value = true
      try {
        await changePassword(finalForm)
        store.dispatch('addToast', {
          type: 'success',
          message: `رمز عبور با موفقیت تغییر یافت.`
        })
      } catch (ex) {
        if (ex.response && ex.response.status == 403) {
          store.dispatch('addToast', {
            type: 'error',
            message: `رمز عبور وارد شده صحیح نمی‌باشد.`
          })
        } else {
          store.dispatch('addToast', {
            type: 'error',
            message: `درخواست شما موفقیت‌آمیز نبود.`
          })
        }
      } finally {
        resetPassItems()
      }
    }

    return {
      modals,
      btnLoading,
      owner,
      localizeDate,
      newPhoneInput,
      newPhoneNumber,
      tempCode,
      changePhone,
      checkNewPhone,
      phoneCode,
      handlePhoneCode,
      resetMobileItems,
      handleChangePassword,
      passForm,
      submitChangePassword,
      resetPassItems,
      newPassInput,
      assignOwner,
      pageStatus
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-component-wrapper {
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
  min-height: calc(100vh - 278px);
}
.load-pending--show {
  height: calc(100vh - 278px);
  img {
    position: relative;
    bottom: 30px;
  }
}

.owner {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  // padding-bottom: 20px;
}

.profile-btn {
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  width: 90%;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border: none;
  padding: 20px 0;
  margin: 0 0 30px;
  height: auto;
  color: black;
  outline: none;
}

.table {
  margin-bottom: 30px;
  background-color: #f2f2f2;
  width: 90%;
  border-radius: 0 0 15px 15px;
}
</style>

<template>
  <div class="cmp-wrapper">
    <navbar msg="افزایش موجودی" />
    <div class="pay-image">
      <img class src="@img/vipon-logo-black.svg" />
    </div>
    <h1 class="pay-title">پیش فاکتور</h1>
    <div class="table table--pay">
      <div class="table__row even--row">
        <h6 class="table__cell cell--title">
          تاریخ
        </h6>
        <i class="table__vr"></i>
        <h6 class="table__cell">
          {{ localizeDate(new Date()) }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row">
        <h6 class="table__cell cell--title">
          شناسه اشتراک
        </h6>
        <i class="table__vr"></i>
        <h6 class="table__cell">
          {{ storeInfo['id'] }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row even--row">
        <h6 class="table__cell cell--title">
          نام فروشگاه
        </h6>
        <i class="table__vr" />
        <h6 class="table__cell">
          {{ storeInfo['name'] }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row">
        <h6 class="table__cell cell--title">
          مبلغ افزایش موجودی
        </h6>
        <i class="table__vr"></i>
        <h6 class="table__cell cell--price">
          {{ price.toLocaleString() }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row even--row">
        <h6 class="table__cell cell--title">
          مالیات
        </h6>
        <i class="table__vr"></i>
        <h6 class="table__cell cell--price ">
          {{ tax.toLocaleString() }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row">
        <h6 class="table__cell cell--title">
          مبلغ قابل پرداخت
        </h6>
        <i class="table__vr"></i>
        <h6 class="table__cell cell--price ">
          {{ total.toLocaleString() }}
        </h6>
      </div>
    </div>
    <form class="credit-buttons" @submit.prevent="submitForm">
      <button class="button button--pay">
        <span class="button__text">پرداخت</span>
        <!-- <spinner v-if="btnLoading" /> -->
      </button>
    </form>
    <fixed-footer />
  </div>
</template>

<script>
import { payPrice } from '@service/ProductService'
import {
  price as validPrice,
  validateForms,
  schemaMaker
} from '@valid/JoiValidation'
import dateConvertor from '@composable/dateConvertor'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'ProfilePay',
  setup() {
    const { localizeDate } = dateConvertor()
    const payUrl = ref(null)

    // compute main items
    const route = useRoute()
    const price = computed(() => parseInt(route.query.price))
    const tax = computed(() => Math.round(price.value * 0.09))
    const total = computed(() => Math.round(1.09 * price.value))
    const storeInfo = ref({ name: '', id: '' })
    // get pay url
    const getUrl = async () => {
      try {
        const { data } = await payPrice(price.value)
        payUrl.value = data['url']
        storeInfo.value['name'] = data['name']
        storeInfo.value['id'] = data['store_id']
      } catch (ex) {
        console.log(ex)
      }
    }
    getUrl()

    // form submition
    const store = useStore()
    const router = useRouter()
    const submitForm = () => {
      const isPriceValid = schemaMaker({
        price: validPrice('مبلغ پرداخت', 10000)
      })
      const errors = validateForms({ price: price.value }, isPriceValid)
      if (errors && errors.length > 0) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }
      const payWin = window.open(payUrl.value, 'Secure Payment')
      const timer = setInterval(() => {
        if (payWin.closed) {
          clearInterval(timer)
          store.dispatch('fetchHomeData')
          router.push({ name: 'Home' })
        }
      }, 300)
    }

    return { localizeDate, price, tax, total, submitForm, storeInfo }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding-top: 80px;
}
.pay-title {
  position: relative;
  width: 90%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(#ddd, 0);
  margin-bottom: 5px;
  font-size: 14px;
  &::after {
    position: absolute;
    content: '';
    background-color: #ccc;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
  }
}
.pay-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    height: 45px;
  }
}

// change in common css
$table-border-color: #bbb;
.table--pay {
  width: 90%;
  background-color: #eee;
  margin-bottom: 10px;
}
.even--row {
  background-color: #ddd;
}
.table--pay .cell--price {
  text-align: right;
}
.table--pay .table__hr {
  height: 1px;
  width: 100%;
  background-color: $table-border-color;
  &:last-child {
    display: none;
  }
}
.table--pay .table__vr {
  width: 1px;
  height: 100%;
  background-color: $table-border-color;
}
.button--pay {
  width: 90%;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(#000, 0.1);
}
</style>
